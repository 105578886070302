import {LoadingOutlined} from "@ant-design/icons";
import React from "react";
import {TimePicker, DatePicker} from 'antd';
import DatePickerLocale from 'antd/es/date-picker/locale/da_DK';
import _ from "lodash";
import moment from "moment";
import "./CalendarEntryEditor.less";
import MMModal from "../../elements/layout/MMModal";
import MMButton from "../../elements/buttons/MMButton";
import MMTriggersComponent from "../../elements/components/MMTriggersComponent";
import ModalController from "../../controllers/ModalController";
import MMSelect from "../../elements/inputs/MMSelect";
import {uuidv4} from "../../providers/data/dataMiddleware";
import MMInlineSelect from "../../elements/inputs/MMInlineSelect";
import {parseDate, parsedDateToDateEntry} from "../../providers/triggers/triggerToCalendarEntries";

class CalendarEntryEditor extends MMTriggersComponent {
    constructor(props) {
        super(props);
        this.modalController = new ModalController(this);
    }

    componentDidMount() {
        super.componentDidMount();
        const is_new = !this.props.timeline_uuid;
        const trigger_id = !is_new ? this.props.trigger_id : uuidv4();
        const inversetrigger_id = !is_new ? this.props.inversetrigger_id : uuidv4();
        const start = is_new ? moment(this.props.date) : null;
        this.setState({
            timeline_uuid: this.props.timeline_uuid,
            new: is_new,
            editable: true,
            trigger_id,
            inversetrigger_id,
            trigger_uuid: !is_new ? this.props.trigger_uuid : trigger_id,
            inversetrigger_uuid: !is_new ? this.props.inversetrigger_uuid : inversetrigger_id
        }, () => {
            if (start) {
                this.onStartChange(start);
            }
            if (!is_new) {
                this.parseExistingEntry();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps);
        if (prevState.start !== this.state.start
            || prevState.end !== this.state.end
            || prevState.repeat !== this.state.repeat
            || prevState.repeat_until !== this.state.repeat_until
            || prevState.TimeTypeStop !== this.state.TimeTypeStop
            || prevState.TimeTypeStart !== this.state.TimeTypeStart
            || prevState.StartOffset !== this.state.StartOffset
            || prevState.StopOffset !== this.state.StopOffset
            || prevState.isWeekend !== this.state.isWeekend
            || prevState.isWeekDays !== this.state.isWeekDays
        ) {
            this.onDateChange();
        }
    }

    triggerIsParsable = trigger => {
        if (!trigger || !trigger.time || !trigger.time.time) {
            return false;
        }
        /*
        for (const prop of ["time", "before", "after"]) {
            if (trigger.time[prop] && (trigger.time[prop].includes("sunset") || trigger.time[prop].includes("sunrise"))) {
                return false;
            }
        }
        */
        return true;
    };

    convertHHMMToSeconds = (timeString) => {
        // console.warn("convertHHMMToSeconds : " + timeString);
        if (timeString === undefined) {
            return "";
        }
        const isNegative = timeString.includes("-");
        // console.warn("isNegative : " + isNegative);
        if (timeString === "") {
            return "";
        }
        const splitTime = timeString.split(':');
        // console.warn(splitTime);
        const hours = parseInt(splitTime[0]);
        const minutes = parseInt(splitTime[1]);

        const seconds = (Math.abs(hours) * 3600) + (minutes * 60);
        if (seconds === 0) {
            return "";
        } if (isNegative) {
            return "-" + Math.abs(seconds);
        }
        return "+" + seconds;
    }

    convertSecondsToHHMM = (seconds) => {
        if (seconds === undefined) {
            return "";
        }
        if (seconds === "") {
            return "";
        }
        seconds = parseInt(seconds);
        // console.warn("seconds : " + seconds);
        const format = val => `0${Math.floor(Math.abs(val))}`.slice(-2);
        const hours = seconds / 3600;
        const minutes = Math.abs(seconds) % 3600 / 60;

        return `${seconds < 0 ? "-" : ""}${format(hours)}:${format(minutes)}`;
    }

    getLastNumericValueWithSign = (str) => {
        console.warn("getLastNumericValueWithSign");
        const match = str.match(/([+-]\d+)(?!.*[+-]\d+)/);
        console.warn("match : " + match);
        return match ? match[0] : null;
    }

    parseExistingEntry = () => {
        const trigger = this.getElement(this.state.trigger_id, "trigger", "id");
        const inversetrigger = this.getElement(this.state.inversetrigger_id, "trigger", "id");
        let TimeTypeStart = null;
        let TimeTypeStop = null;
        let StopOffset = "00:00";
        let StartOffset = "00:00";
        let isWeekDay = false;
        let isWeekEnd = false;

        console.warn("parseExistingEntry trigger : " + trigger);

        if (trigger) {
            let parts = trigger.time.time.split(" ");
            if (trigger.time.time.includes("weekdays")) {
                isWeekDay = true;
                trigger.time.time = parts[1];
            } else if (trigger.time.time.includes("weekend")) {
                isWeekEnd = true;
                trigger.time.time = parts[1];
            }
            parts = trigger.time.time.split(" ");
            if (trigger.time.time.includes("sunrise")) {
                TimeTypeStart = "sunrise";
                StartOffset = this.convertSecondsToHHMM(this.getLastNumericValueWithSign(trigger.time.time));
                trigger.time.time = parts[0];
            } else if (trigger.time.time.includes("sunset")) {
                TimeTypeStart = "sunset";
                StartOffset = this.convertSecondsToHHMM(this.getLastNumericValueWithSign(trigger.time.time));
                trigger.time.time = parts[0];
            } else {
                TimeTypeStart = "time";
            }
        }
        if (inversetrigger) {
            let parts = inversetrigger.time.time.split(" ");
            if (inversetrigger.time.time.includes("weekdays")) {
                isWeekDay = true;
                isWeekEnd = true;
                inversetrigger.time.time = parts[1];
            } else if (trigger.time.time.includes("weekend")) {
                isWeekEnd = true;
                isWeekDay = true;
                inversetrigger.time.time = parts[1];
            }
            parts = inversetrigger.time.time.split(" ");
            if (inversetrigger.time.time.includes("sunrise")) {
                TimeTypeStop = "sunrise";
                StopOffset = this.convertSecondsToHHMM(this.getLastNumericValueWithSign(inversetrigger.time.time));
                inversetrigger.time.time = parts[0];
            } else if (inversetrigger.time.time.includes("sunset")) {
                TimeTypeStop = "sunset";
                StopOffset = this.convertSecondsToHHMM(this.getLastNumericValueWithSign(inversetrigger.time.time));
                inversetrigger.time.time = parts[0];
            } else {
                TimeTypeStop = "time";
            }
            console.warn("inversetrigger.time.time : " + inversetrigger.time.time);
        }
        console.warn("parseExistingEntry StartOffset  : " + StartOffset);
        console.warn("parseExistingEntry StopOffset : " + StopOffset);
        /*
                if (!this.triggerIsParsable(trigger) || !this.triggerIsParsable(inversetrigger)
                    || !objectsAreEqual(trigger.time.before, inversetrigger.time.before)
                    || !objectsAreEqual(trigger.time.after, inversetrigger.time.after)) {
                    this.setState({editable: false});
                    return;
                }
        */
        if (!this.triggerIsParsable(trigger) || !this.triggerIsParsable(inversetrigger)) {
            this.setState({editable: false});
            return;
        }
        if (!trigger) {
            console.error("Failed to find trigger with id: " + this.state.trigger_id);
            return;
        }
        if (!inversetrigger) {
            console.error("Failed to find inverse trigger with id: " + this.state.inversetrigger_id);
            return;
        }

        const trigger_time_parsed = parseDate(trigger.time.time);
        const inversetrigger_time_parsed = parseDate(inversetrigger.time.time);
        let repeat = null;
        let repeat_until = null;
        let start;
        let end;

        if (trigger.time.after) {
            start = moment(trigger.time.after);
            end = moment(parsedDateToDateEntry(inversetrigger_time_parsed, start.toDate()));
            repeat_until = moment(trigger.time.before);

            const {
                day,
                weekDay,
                year,
                month
            } = trigger_time_parsed;
            repeat = "dag";
            if (weekDay) {
                repeat = "uge";
            }
            if (day) {
                repeat = "måned";
            }
            if (month) {
                repeat = "år";
            }
            if (year) {
                repeat = null;
            }

            /* if (!year) {
                repeat = "år";
                if (!month) {
                    repeat = "måned";
                    if (!day) {
                        repeat = "uge";
                        if (!weekDay) {
                            repeat = "dag";
                        }
                    }
                }
            } */
        } else {
            start = moment(trigger.time.time);
            end = moment(parsedDateToDateEntry(inversetrigger_time_parsed, start.toDate()));
        }

        // Replace triggers if they're tied to multiple timelines
        const other_timelines_are_using_trigger = this.getTimelinesUsingTrigger(trigger.id, ["trigger", "inversetrigger"])
            .some(t => t.uuid !== this.state.timeline_uuid);
        const other_timelines_are_using_inversetrigger = this.getTimelinesUsingTrigger(inversetrigger.id, ["trigger", "inversetrigger"])
            .some(t => t.uuid !== this.state.timeline_uuid);

        let p = Promise.resolve();
        if (other_timelines_are_using_trigger) {
            p = p.then(() => this.replaceTriggerOnTimeline(trigger));
        }
        if (other_timelines_are_using_inversetrigger) {
            p = p.then(() => this.replaceTriggerOnTimeline(inversetrigger, "inversetrigger"));
        }
        p.then(() => this.setState({
            start,
            end,
            repeat,
            TimeTypeStop,
            TimeTypeStart,
            repeat_until,
            StartOffset,
            StopOffset,
            isWeekDay,
            isWeekEnd
        }));
    };

    deleteEntry = () => {
        const trigger_changes = [];

        if (this.state.trigger_id) {
            const timelines_using_trigger = this.getTimelinesUsingTrigger(this.state.trigger_id, ["trigger", "inversetrigger"]);
            if (timelines_using_trigger.length <= 1) {
                // This entry is the only one using this trigger, delete it
                trigger_changes.push({
                    uuid: this.state.trigger_uuid,
                    delete: true
                });
            }
        }
        if (this.state.inversetrigger_id) {
            const timelines_using_trigger = this.getTimelinesUsingTrigger(this.state.inversetrigger_id, ["trigger", "inversetrigger"]);
            if (timelines_using_trigger.length <= 1) {
                // This entry is the only one using this trigger, delete it
                trigger_changes.push({
                    uuid: this.state.inversetrigger_uuid,
                    delete: true
                });
            }
        }
        this.removeTimelineEntry(this.state.timeline_uuid)
            .then(() => this.setTypeChanges(trigger_changes))
            .then(() => this.saveChanges());
    };

    discardChanges = () => {
        this.modalController.close();
    };

    replaceTriggerOnTimeline = (trigger, type = "trigger") => {
        const id = uuidv4();
        const new_trigger = {
            ...trigger,
            id,
            uuid: id
        };
        return this.removeTriggerOnTimeline(trigger.id, this.state.timeline_uuid, [type])
            .then(() => this.setTypeChanges(new_trigger))
            .then(() => this.addTriggerOnTimeline(id, this.state.timeline_uuid, type))
            .then(() => new Promise(resolve => this.setState({
                [type + "_id"]: id,
                [type + "_uuid"]: id
            }, resolve)));
    };

    removeTimelineEntry = timeline_uuid => {
        if (!timeline_uuid) {
            return Promise.resolve();
        }

        let p = Promise.resolve();
        if (this.state.trigger_id) {
            p = p.then(() => this.removeTriggerOnTimeline(this.state.trigger_id, timeline_uuid));
        }
        if (this.state.inversetrigger_id) {
            p = p.then(() => this.removeTriggerOnTimeline(this.state.inversetrigger_id, timeline_uuid, ["inversetrigger"]));
        }
        return p;
    };

    addTimelineEntry = timeline_uuid => {
        const timeline = _.cloneDeep(this.getElement(timeline_uuid, "timeline"));
        if (!timeline) {
            console.error("Failed to find timeline", timeline_uuid);
            return;
        }
        if (this.state.trigger_id && !timeline.trigger.includes(this.state.trigger_id)) {
            timeline.trigger.push(this.state.trigger_id);
        }
        if (this.state.inversetrigger_id && !timeline.inversetrigger.includes(this.state.inversetrigger_id)) {
            timeline.inversetrigger.push(this.state.inversetrigger_id);
        }
        return this.setTypeChanges({
            uuid: timeline.uuid,
            trigger: timeline.trigger,
            inversetrigger: timeline.inversetrigger
        }, "timeline");
    };

    onChangeTimeline = timeline_uuid => {
        this.removeTimelineEntry(this.state.timeline_uuid) // Remove the triggers from the old timeline
            .then(() => this.addTimelineEntry(timeline_uuid)) // Add the triggers to the new timeline
            .then(() => this.setState({timeline_uuid})); // Set MMSelect
    };

    onStartChange = start => {
        console.warn("Setting start : " + start);
        this.setState({start});
    };

    onEndChange = end => {
        console.warn("Setting end : " + end);
        this.setState({end});
    };

    onChangeStartOffset = StartOffset => {
        // console.warn("StartOffset : " + StartOffset);
        this.setState({StartOffset});
    };

    onChangeStopOffset = StopOffset => {
        // console.warn("StopOffset : " + StopOffset);
        this.setState({StopOffset});
    };

    onDateChange = () => {
        const start = this.state.start;
        const end = this.state.end;
        const repeat = this.state.repeat;
        const TimeTypeStart = this.state.TimeTypeStart;
        const TimeTypeStop = this.state.TimeTypeStop;
        const StartOffset = this.state.StartOffset;
        const StopOffset = this.state.StopOffset;
        const repeat_until = this.state.repeat_until;
        let start_time;
        let end_time;
        let before;
        let after;
        let before_inverse;
        let after_inverse;
        let start_time_time;
        let end_time_time;
        if (!start || !end) {
            return;
        }
        // console.warn(this.convertHHMMToSeconds(StartOffset));
        // console.warn(this.convertHHMMToSeconds(StopOffset));
        start_time_time = start.format("HH:mm:ss");
        end_time_time = end.format("HH:mm:ss");
        if (TimeTypeStart === "sunrise" || TimeTypeStart === "sunset") {
            console.warn("TimeTypeStart : " + TimeTypeStart + this.convertHHMMToSeconds(StartOffset));
            start_time_time = TimeTypeStart + this.convertHHMMToSeconds(StartOffset);
        }
        if (TimeTypeStop === "sunrise" || TimeTypeStop === "sunset") {
            console.warn("TimeTypeStop : " + TimeTypeStop + this.convertHHMMToSeconds(StopOffset));
            end_time_time = TimeTypeStop + this.convertHHMMToSeconds(StopOffset);
        }
        if (!repeat) {
            start_time = start.format("YYYY-MM-DD") + " " + start_time_time;
            end_time = end.format("YYYY-MM-DD") + " " + end_time_time;
        } else {
            if (!repeat_until) {
                return;
            }
            after = start.format("YYYY-MM-DD") + " 00:00:00";
            before = repeat_until.format("YYYY-MM-DD") + " 00:00:00";
            after_inverse = start.format("YYYY-MM-DD") + " 00:00:00";
            before_inverse = repeat_until.format("YYYY-MM-DD") + " 23:59:59";
            switch (repeat) {
                case "år":
                    start_time = start.format("MM-DD") + " " + start_time_time;
                    end_time = end.format("MM-DD") + " " + end_time_time;
                    break;
                case "måned":
                    start_time = start.format("DD") + " " + start_time_time;
                    end_time = end.format("DD") + " " + end_time_time;
                    break;
                case "uge":
                    start_time = start.format("ddd") + " " + start_time_time;
                    end_time = end.format("ddd") + " " + end_time_time;
                    break;
                case "dag":
                    start_time = start_time_time;
                    end_time = end_time_time;
                    break;
                default:
                    break;
            }
        }
        // console.warn("after_inverse : " + after_inverse + " after : " + after + " before_inverse : " + before_inverse + " before : " + before);
        let dummy_time = {before, time: start_time, after};
        this.setTypeChanges(
            {
                uuid: this.state.trigger_uuid,
                id: this.state.trigger_id,
                time: dummy_time
            }
        );
        before = before_inverse;
        after = after_inverse;
        dummy_time = {before, time: end_time, after};
        this.setTypeChanges(
            {
                uuid: this.state.inversetrigger_uuid,
                id: this.state.inversetrigger_id,
                time: dummy_time
            }
        );
    };

    onRepeatUntilChange = repeat_until => this.setState({repeat_until});

    generateOffsets = () => {
        const options = [];
        for (let seconds = -6 * 3600; seconds <= 6 * 3600; seconds += 15 * 60) {
            const time = this.convertSecondsToHHMM(seconds);
            options.push(time);
        }
        /*
        for (let i = -6; i <= 6; i++) {
            for (let j = 0; j < 60; j += 15) {
                const sign = i < 0 ? '-' : '+';
                const hour = Math.abs(i);
                const minutes = j < 10 ? '0' + j : j;
                const time = `${sign}${String(hour).padStart(2, '0')}:${minutes}`;
                options.push(time);
            }
        }

         */
        return options;
    }

    renderEdit = () => {
        const offsetOptions = this.generateOffsets();
        const MMoffsetOptions = offsetOptions.map(offset => ({key: offset, value: offset}));
        return (
            <div>
                <p>Start</p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <DatePicker
                        format="ddd DD MMM YYYY"
                        locale={DatePickerLocale}
                        value={this.state.start}
                        onChange={this.onStartChange}
                        disabledDate={current => current >= this.state.end}
                    />
                    <MMInlineSelect
                        style={{height: 20}}
                        options={[
                            {
                                key: "time",
                                value: "TID"
                            },
                            {
                                key: "sunrise",
                                value: "SOL OP"
                            },
                            {
                                key: "sunset",
                                value: "SOL NED"
                            }
                        ]}
                        value={this.state.TimeTypeStart}
                        onChange={TimeTypeStart => this.setState({TimeTypeStart})}
                    />
                    {
                        this.state.TimeTypeStart === "time"
                        && (
                            <TimePicker
                                format="HH:mm"
                                showTime="HH:mm"
                                value={this.state.start}
                                onChange={this.onStartChange}
                            />)
                    }
                    {
                        (this.state.TimeTypeStart === "sunrise" || this.state.TimeTypeStart === "sunset") && (
                            <MMSelect
                                options={MMoffsetOptions}
                                onChange={this.onChangeStartOffset}
                                value={this.state.StartOffset}
                            />
                        )
                    }
                </div>
                <p>Slut</p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <DatePicker
                        format="ddd DD MMM YYYY"
                        locale={DatePickerLocale}
                        value={this.state.end}
                        onChange={this.onEndChange}
                        disabledDate={current => current && current <= this.state.start}
                    />
                    <MMInlineSelect
                        options={[
                            {
                                key: "time",
                                value: "TID"
                            },
                            {
                                key: "sunrise",
                                value: "SOL OP"
                            },
                            {
                                key: "sunset",
                                value: "SOL NED"
                            }
                        ]}
                        value={this.state.TimeTypeStop}
                        onChange={TimeTypeStop => this.setState({TimeTypeStop})}
                    />
                    {
                        this.state.TimeTypeStop === "time"
                        && (
                            <TimePicker
                                format="HH:mm"
                                showTime="HH:mm"
                                value={this.state.end}
                                onChange={this.onEndChange}
                            />)
                    }
                    {
                        (this.state.TimeTypeStop === "sunrise" || this.state.TimeTypeStop === "sunset") && (
                            <MMSelect
                                options={MMoffsetOptions}
                                onChange={this.onChangeStopOffset}
                                value={this.state.StopOffset}
                            />
                        )
                    }
                </div>
                <p>Gentag</p>
                <MMInlineSelect
                    options={[
                        {
                            key: "dag",
                            value: "Dagligt"
                        },
                        {
                            key: "uge",
                            value: "Ugentligt"
                        },
                        {
                            key: "måned",
                            value: "Månedligt"
                        },
                        {
                            key: "år",
                            value: "Årligt"
                        }
                    ]}
                    value={this.state.repeat}
                    onChange={repeat => this.setState({repeat})}
                />
                {this.state.repeat
                    && (
                        <div>
                            <p>Gentag indtil</p>
                            <DatePicker
                                format="ddd DD MMM YYYY"
                                locale={DatePickerLocale}
                                value={this.state.repeat_until}
                                onChange={this.onRepeatUntilChange}
                                disabledDate={current => current <= this.state.end}
                            />
                        </div>)}
            </div>);
    }

    invalidInput = () => this.state.new && (!this.state.start || !this.state.end || (this.state.repeat && !this.state.repeat_until)
        || !this.state.timeline_uuid);

    render() {
        return (
            <MMModal
                width={800}
                close={this.state.closing}
                closable={!this.state.loading}
                onClose={() => {
                    this.setState({closing: true});
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                }}
                className={`CalendarEntryEditor ${this.props.className ? this.props.className : ""} ${this.state.loading ? "loading" : ""}`}
                header={
                    [
                        <div className="align-left" key="align-left">
                            <p className="action-title">
                                {this.state.new ? "Tilføj " : "Redigér "}
                                begivenhed
                            </p>
                            <LoadingOutlined className="loading-icon"/>
                        </div>
                    ]
                }
                footer={
                    <div>
                        {!this.state.new
                            && (
                                <MMButton
                                    className="delete red"
                                    style={{width: 150}}
                                    onClick={this.deleteEntry}
                                    disabled={this.state.loading}
                                >
                                    Slet begivenhed
                                </MMButton>)}
                        <div className="align-right">
                            <MMButton
                                className="cancel grey"
                                style={{width: 200}}
                                onClick={() => this.discardChanges()}
                                disabled={this.state.loading}
                            >
                                Annullér
                            </MMButton>
                            <MMButton
                                className="submit"
                                style={{width: 200}}
                                disabled={!this.hasChanges() || this.state.loading || this.invalidInput()}
                                onClick={() => this.saveChanges()}
                            >
                                Gem ændringer
                            </MMButton>
                        </div>
                    </div>
                }
            >
                <p>Scenarie</p>
                <MMSelect
                    options={this.getElements("timeline")
                        .map(t => ({
                            key: t.uuid,
                            value: t["webapp-name"] || t.name
                        }))}
                    onChange={this.onChangeTimeline}
                    value={this.state.timeline_uuid}
                />
                {this.state.editable && this.renderEdit()}
            </MMModal>
        );
    }
}

export default MMTriggersComponent.connect(CalendarEntryEditor);
