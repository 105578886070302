import React from "react";
import {push} from "react-router-redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import daLocale from '@fullcalendar/core/locales/da';
import CalendarEntryEditor from "./CalendarEntryEditor";
import MMTriggersComponent from "../../elements/components/MMTriggersComponent";
import MMButton from "../../elements/buttons/MMButton";
import objectsAreEqual from "../../providers/objectsAreEqual";
import TriggerCalendarController from "../../controllers/TriggerCalendarController";
import ControllerTimeController from "../../controllers/ControllerTimeController";
import SceneController from "../../controllers/SceneController";
import {APP_CONFIG} from "../../config";
import {notificationsActions} from "../../providers/notifications/notificationsActions";

const event_colors = ["#3f3f3f", "#7f7f3f", "#7f3f3f", "#fee4a6",
    "#b0e0a8", "#ab93c9", "#ffc785", "#7fe7cc",
    "#dcedc2", "#ffecda", "#ffa5a5", "#74bec1"];

class TriggersCalendar extends MMTriggersComponent {
    #scene_controller = null;

    #trigger_calendar_controller = null;

    #controlller_time_controller = null;

    initialView = "dayGridMonth";

    constructor(props) {
        super(props);
        const view = props.location.pathname.split("/")[2];
        if (view) {
            this.initialView = view;
        }

        this.#scene_controller = new SceneController();
        this.#trigger_calendar_controller = new TriggerCalendarController(this.timeline_controller, this.#scene_controller);
        this.#trigger_calendar_controller.setEventColors(event_colors);
        this.#controlller_time_controller = new ControllerTimeController(this.onTimeChange, 1000 * 60 * 10); // Recalculate time every 10 minutes
    }

    componentDidMount() {
        super.componentDidMount();
        this.#scene_controller.setElements(this.getElements("scene"));
        this.#trigger_calendar_controller.setTriggers(this.props.elements);
        this.#controlller_time_controller.start();
        this.calculateCalendarEntries();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps);
        const viewDatesChanged = !prevState || !prevState.start || (prevState.start - this.state.start !== 0
                || prevState.end - this.state.end !== 0)
            && (this.#trigger_calendar_controller.getStart() > this.state.start || this.#trigger_calendar_controller.getEnd() < this.state.end);
        const lightConfigDataUpdated = !objectsAreEqual(prevProps.data.light_config, this.props.data.light_config);
        if (viewDatesChanged || lightConfigDataUpdated) {
            this.calculateCalendarEntries();
        }
        if (prevProps.data.time !== this.props.data.time || prevProps.data.time_updated_at !== this.props.data.time_updated_at) {
            this.#controlller_time_controller.update(this.props.data.time, this.props.data.time_updated_at);
        }
    }

    onDataUpdated = () => {
        super.onDataUpdated();
        this.#scene_controller.setElements(this.getElements("scene"));
        this.#trigger_calendar_controller.setTriggers(this.props.elements);
    };

    componentWillUnmount() {
        this.#controlller_time_controller.stop();
    }

    calculateCalendarEntries = () => {
        if (!this.state) {
            return;
        }

        this.#trigger_calendar_controller.setStart(this.state.start);
        this.#trigger_calendar_controller.setEnd(this.state.end);
        this.#trigger_calendar_controller.calculate();
        this.setState({
            entries: this.#trigger_calendar_controller.getEntries()
        });
    };

    onTimeChange = date_time => {
        this.setState({
            currentTime: date_time
        });
    };

    onViewDateChange = event => {
        const start = new Date(event.start);
        const end = new Date(event.end);
        const delta = end - start;

        // Add the delta on both sides, because we need to show events that only end in the current timeframe
        start.setMilliseconds(start.getMilliseconds() - delta);
        end.setMilliseconds(end.getMilliseconds() + delta);

        this.setState({
            start,
            end
        });
    };

    openEntryEditor = props => {
        this.setState({
            modal: (<CalendarEntryEditor onClose={() => this.setState({modal: null})} {...props}/>)
        });
    };

    onEventClick = event => {
        const timeline_uuid = event.event.extendedProps.timeline.uuid;
        const trigger_uuid = event.event.extendedProps.uuid;
        const trigger_id = event.event.id;
        const inversetrigger_uuid = event.event.extendedProps.inversetrigger && event.event.extendedProps.inversetrigger.uuid;
        const inversetrigger_id = event.event.extendedProps.inversetrigger && event.event.extendedProps.inversetrigger.id;
        const end_timeline_uuid = event.event.extendedProps.end_timeline && event.event.extendedProps.end_timeline.uuid;

        let editable = APP_CONFIG && APP_CONFIG.ALLOW_EDITING_LOCKED_CUELISTS === true;
        editable |= event.event.extendedProps.timeline["webapp-locked"] !== "yes";
        // console.warn("Editable : " + editable);
        if (!editable) {
            this.props.dispatch({type: notificationsActions.EDIT_DISALLOWED});
            return;
        }

        this.openEntryEditor({
            timeline_uuid,
            trigger_uuid,
            trigger_id,
            inversetrigger_uuid,
            inversetrigger_id,
            end_timeline_uuid
        });
    };

    onDateClick = event => {
        this.openEntryEditor({date: event.date});
    };

    onViewChanged = event => {
        const pathname_arr = this.props.location.pathname.split("/");
        if (pathname_arr[2] !== event.view.type) {
            this.props.dispatch(push(`/${pathname_arr[1]}/${event.view.type}`));
        }
    };

    render() {
        return (
            <div className="CalendarPage">
                {this.state && this.state.modal}
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView={this.initialView}
                    height="100%"
                    contentHeight={400}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    }}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                        hour12: false
                    }}
                    locale={daLocale}
                    eventTextColor="white"
                    nowIndicator
                    weekNumbers
                    weekText="Uge "
                    now={this.state && this.state.currentTime} // Doesn't work: https://github.com/fullcalendar/fullcalendar/issues/3783
                    events={this.state && this.state.entries}
                    firstDay={1}
                    datesSet={this.onViewDateChange}
                    eventClick={this.onEventClick}
                    dateClick={this.onDateClick}
                    eventOrder="priority"
                    viewClassNames={this.onViewChanged}
                    allDaySlot={false}
                />
                <MMButton className="add-trigger" onClick={this.onDateClick}>Tilføj begivenhed</MMButton>
            </div>
        );
    }
}

export default connect(TriggersCalendar.mapStateToProps)(withRouter(TriggersCalendar));
