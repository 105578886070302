import parser from "any-date-parser";
import SunCalc from "suncalc";

const days = [
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
    "sun"
];

const tryParsingSun = time => {
    // console.warn(time);
    let date = null;
    let offset = 0;
    let circadian_type = null;
    if (time === undefined) {
        return null;
    }
    if (time.includes("sunrise")) {
        circadian_type = "sunrise";
    } else if (time.includes("sunset")) {
        circadian_type = "sunset";
    } else {
        throw new Error("Couldn't parse " + time);
    }
    const split = time.split(circadian_type);
    offset = parseInt(split[split.length - 1]);
    if (isNaN(offset)) {
        offset = 0;
    }
    // console.warn("split length : " + split.length + "split[0] : " + split[0]);
    if (split[0] !== undefined && split[0] !== "") {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        date = parseDate(split[0]);
    }
    // console.warn("date : " + date + "split : " + split + " offset : " + offset);
    let retval = {
        sun: circadian_type,
        offset
    };
    if (date !== undefined && date !== null && date !== "") {
        retval = {...retval, ...date};
    }
    return retval;

    /*
    console.warn("time : " + time);
    const arr0 = time.split(" ");
    console.warn("arr0 : " + arr0);
    let mod = -1;
    const sun_pos = arr0.find(x => x.includes("sunrise") || x.includes("sunset"));
    if (!sun_pos) {
        throw new Error("Couldn't parse " + time);
    }
    console.warn("sunpos : " + sun_pos);
    let arr = sun_pos.split("-");
    const positive_arr = sun_pos.split("+");
    if (positive_arr.length > arr.length) {
        arr = positive_arr;
        mod = 1;
    }
    console.warn("arr[0] : " + arr[0] + " arr[1] : " + arr[1]);
    if (arr[0] === "sunset" || arr[0] === "sunrise") {
        let offset = parseInt(arr[1]);
        if (isNaN(offset)) {
            offset = 0;
        }
        if (offset !== 0) { // Jest thinks there's a difference between -0 and 0
            offset *= mod;
        }
        let retval = {
            sun: arr[0],
            offset
        };
        if (arr0.length > 1) {
            // eslint-disable-next-line
            const date = parseDate(arr0[0]);
            retval = {...retval, ...date};
        }
        return retval;
    }
    throw new Error("Second Couldn't parse " + time);

     */
};

const tryParsingMonthDay = time => {
    const arr = time.split(" ");
    if (arr.length !== 2) {
        throw new Error("Couldn't parse " + time);
    }
    const parsed_date = parser.attempt(arr[1]);
    parsed_date.day = parseInt(arr[0]);
    if (parsed_date.invalid || isNaN(parsed_date.day)) {
        throw new Error("Couldn't parse " + time);
    }
    // console.warn("Foound : " + parsed_date);
    return parsed_date;
};

const tryParsingWeekDay = time => {
    // console.warn("tryParsingWeekDay");
    const day = days.find(e => time.toLowerCase()
        .split(" ")[0] === e);
    if (!day) {
        throw new Error("Couldn't parse " + time);
    }
    const arr = time.split(" ");
    if (arr.length === 1) {
        return {
            weekDay: time
        };
    }
    if (arr.length !== 2) {
        throw new Error("Couldn't parse " + time);
    }
    const parsed_date = parser.attempt(arr[1]);
    parsed_date.weekDay = day;
    if (parsed_date.invalid) {
        throw new Error("Couldn't parse " + time);
    }
    // console.warn("Found : " + parsed_date.toString());
    return parsed_date;
};

const sunCalc = start_time => {
    const latitude = 56.202064;
    const longitude = 10.231917;
    const elevation = 4.49;
    // TODO: Read coordinates from controller
    let sunrise = null;
    let sunset = null;
    const it_time = new Date(start_time);
    while (start_time > sunrise || start_time > sunset) {
        const sun_calculations = SunCalc.getTimes(it_time, latitude, longitude, elevation);
        if (start_time > sunrise) {
            sunrise = sun_calculations.sunrise;
        }
        if (start_time > sunset) {
            sunset = sun_calculations.sunset;
        }
        it_time.setDate(it_time.getDate() + 1); // Next day
    }
    return {
        sunrise: sunrise && Math.round(sunrise.getTime()),
        sunset: sunset && Math.round(sunset.getTime())
    };
};

const formatter = new Intl.DateTimeFormat('da-DK', {
    timeZone: "Europe/Copenhagen",
    dateStyle: 'short',
    timeStyle: 'short'
});

const lastDayInMonth = (year, month) => {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
};

const incrementDateEntry = (parsed_date, start_time, dateEntry, date_key) => {
    while (dateEntry.getTime() < start_time.getTime()) {
        if (date_key === "hour" && !parsed_date.day
            && (!parsed_date.month || dateEntry.getDate() < lastDayInMonth(dateEntry.getFullYear(), dateEntry.getMonth()))) {
            dateEntry.setDate(dateEntry.getDate() + 1); // Jump to the next day
        } else if (date_key === "day" && !parsed_date.month && dateEntry.getMonth() < 11) {
            dateEntry.setMonth(dateEntry.getMonth() + 1); // Jump to the next month
        } else if (date_key === "month" && !parsed_date.year) {
            dateEntry.setFullYear(dateEntry.getFullYear() + 1); // Jump to the next year
        } else {
            return null; // Will never get past start time
        }
    }
    return dateEntry;
};

export const parsedDateToDateEntry = (parsed_date, start_time, end_time = null, exception_on_recursion = false) => {
    if (!parsed_date || (end_time && start_time >= end_time)) {
        return null;
    }
    const entries = Object.entries(parsed_date);
    if (entries.length === 0) {
        return null;
    }
    let dateEntry = new Date(start_time);
    for (const [key, value] of entries) {
        switch (key) {
            case "year":
                dateEntry.setFullYear(value);
                break;
            case "month":
                dateEntry.setMonth(value - 1);
                break;
            case "day":
                dateEntry.setDate(value);
                break;
            case "hour":
                dateEntry.setHours(value);
                break;
            case "minute":
                dateEntry.setMinutes(value);
                break;
            case "second":
                dateEntry.setSeconds(value);
                break;
            case "sun": {
                const calc = sunCalc(dateEntry);
                if (value === "sunrise") {
                    dateEntry = new Date(calc.sunrise + parsed_date.offset * 1000);
                } else if (value === "sunset") {
                    dateEntry = new Date(calc.sunset + parsed_date.offset * 1000);
                }
                break;
            }
            case "offset": // Ignore
                break;
            case "weekDay": {
                const distance = days.indexOf(value) - dateEntry.getDay() + 1;
                dateEntry.setDate(dateEntry.getDate() + distance);
                break;
            }
            case "isWeekDay":
                // console.warn("isWeekDay, parsed_date " + dateEntry + " Day : " + dateEntry.getDay());
                if (value === true && (dateEntry.getDay() === 0 || dateEntry.getDay() === 6)) {
                    // console.warn("NOT A WEEKDAY");
                    return null;
                }
                break;
            case "isWeekEnd":
                if (value === true && (dateEntry.getDay() > 0 && dateEntry.getDay() < 6)) {
                    // console.warn("NOT WEEKEND");
                    return null;
                }
                break;
            default:
                console.error("Failed to parse date: ", parsed_date);
                return null;
        }
        dateEntry = incrementDateEntry(parsed_date, start_time, dateEntry, key);
        if (!dateEntry) {
            break;
        }
    }

    if (dateEntry && dateEntry < start_time) {
        // Try again
        if (!end_time) {
            end_time = new Date(start_time);
            end_time.setFullYear(end_time.getFullYear() + 1); // Next year
        }
        console.error("Failed parsing time on first try", parsed_date, {
            end_time: end_time && formatter.format(end_time),
            dateEntry: dateEntry && formatter.format(dateEntry),
            start_time: start_time && formatter.format(start_time)
        });

        if (exception_on_recursion) {
            throw new Error("Failed to parse time on first try");
        }

        const next_start_time = new Date(start_time);
        next_start_time.setDate(next_start_time.getDate() + 1); // Next day
        return parsedDateToDateEntry(parsed_date, next_start_time, end_time);
    }
    if (end_time && dateEntry > end_time) {
        dateEntry = null;
    }

    return dateEntry;
};

export const parseDate = time => {
    console.warn("parseDate : " + time);
    let parsed_date;
    let isWeekEnd = false;
    let isWeekDay = false;
    if (time !== undefined) {
        if (time.includes("weekday")) {
            isWeekDay = true;
            isWeekEnd = false;
            time = time.split(" ")[1];
        } else if (time.includes("weekend")) {
            time = time.split(" ")[1];
            isWeekDay = false;
            isWeekEnd = true;
        }
    }
    parsed_date = parser.attempt(time);
    if (parsed_date.invalid) {
        try {
            parsed_date = tryParsingWeekDay(time);
        } catch (a) {
            try {
                parsed_date = tryParsingMonthDay(time);
            } catch (b) {
                parsed_date = tryParsingSun(time);
            }
        }
    }
    if (parsed_date !== undefined && parsed_date !== null) {
        parsed_date.isWeekDay = isWeekDay;
        parsed_date.isWeekEnd = isWeekEnd;
    }
    return parsed_date;
};

export const triggerToCalendarEntries = (trigger, start_time, end_time, first_only = false) => {
    const entries = [];
    const parsed_date = parseDate(trigger.time.time);
    const parsed_before_date = trigger.time.before && parseDate(trigger.time.before);
    const parsed_after_date = trigger.time.after && parseDate(trigger.time.after);

    let it_time = new Date(start_time);

    while (it_time <= end_time) {
        const after = parsedDateToDateEntry(parsed_after_date, start_time);
        if (after > it_time) {
            it_time = new Date(after);
        }
        const start = parsedDateToDateEntry(parsed_date, it_time);
        const before = parsedDateToDateEntry(parsed_before_date, after || it_time);

        let within_before_and_after = true;
        if (parsed_before_date) {
            within_before_and_after = start <= before;
        }
        if (parsed_after_date && within_before_and_after) {
            within_before_and_after = after <= start;
        }
        const is_after_start = start >= start_time;
        const duplicate = entries.some(e => e.start - start === 0);
        if (start && within_before_and_after && is_after_start && !duplicate) {
            // console.warn("Entries : " + entries);
            entries.push({
                ...trigger,
                date: parsed_date,
                start,
            });
            if (first_only) {
                break;
            }
        }
        it_time.setDate(it_time.getDate() + 1); // Next day
    }
    return entries;
};
